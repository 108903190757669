<template>
  <div class='container'>
    <div class="reBox">
      <h5>
          <img src="@assets/img/profile/icon-back.png" @click="$router.replace('/myWallet')"/>申请提现
      </h5>
      <div class="content">
          <div class="withdrawal">
              <div class="balance">
                  <div class="balance-text">
                      <p>￥{{withdrawInfo.member_wallet}}</p>
                      <span>账户余额</span>
                  </div>
                  <div class="withdrawal-desc">
                      <span>提现说明：</span>
                      <div class="desc" v-html="withdrawInfo.news">
<!--                          <p>{{ withdrawInfo.news }}</p>-->
  <!--                        <p>2.都算得上是官方公告的方法</p>-->
  <!--                        <p>3.都算得上是官方公告的方法</p>-->
                      </div>
                  </div>
              </div>
              <div class="withdrawal-content">
                  <div class="withdrawal-input">
                      <span>本次提现金额：</span>
                      <input type="text" v-model.trim="money" @input="countRealmoney" placeholder="请输入金额" autocomplete="off"/>
                      <span style="margin-left:5px;">元</span>
                      <i>实际到账金额：{{ realMoney }}元</i>
                  </div>
                  <h4>支付宝信息</h4>
                  <div class="info-item">
                      <span>姓名：</span>
                      <input type="text" v-model.trim="aliName" placeholder="请输入姓名" autocomplete="off"/>
                  </div>
                  <div class="info-item">
                      <span>账号：</span>
                      <input type="text" v-model.trim="aliAccount" placeholder="请输入账号" autocomplete="off"/>
                  </div>
                  <div class="btn" @click="submit">确认提现</div>
              </div>
          </div>
          <h6>提现明细</h6>
          <div class="withdrawal-list">
            <table>
            <colgroup>
              <col style="width:20%">
              <col style="width:20%">
              <col style="width:20%">
              <col style="width:20%">
              <col style="width:20%">
            </colgroup>
              <thead>
                <tr>
                  <th>订单号</th>
                  <th>提现金额</th>
                  <th>提现状态</th>
                  <th>提交时间</th>
                  <th>反馈时间</th>
                </tr>
              </thead>
            </table>
            <div class="pan-table__body" @scroll="eventScrooll($event)">
              <table>
              <colgroup>
                <col style="width:20%">
                <col style="width:20%">
                <col style="width:20%">
                <col style="width:20%">
                <col style="width:20%">
              </colgroup>
             <tbody>
              <tr v-for="(item,index) in tableList" :key="item.id">
                <td>{{item.order_number}}</td>
                <td>￥{{item.actual_price}}</td>
                <td>{{item.status | withdrawalType }}</td>
                <td>{{item.create_time}}</td>
                <td>{{item.update_time?item.update_time:'-'}}</td>
              </tr>
            </tbody>
            </table>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getWithdraw, sendWithdraw} from "@assets/api/myWallet/myWallet";

export default {
  name:'',
  components: {},

  data () {
    return {
      money:'',
      aliName:'',
      aliAccount:'',
      pageIndex:1,//分页
      withdrawInfo:{},//提现数据
      tableList:[],
      realMoney:0,//真实到账
    };
  },

  watch:{
    money(){
      if(this.money.length > 1 && this.money.substr(0,1) ==0 && this.money.substr(1,1) !='.'){
          this.money= this.money.substr(1,1)
      }
      this.money = this.money.replace(/[^\d.]/g,"");//清除"数字"和"."以外的字符
      this.money = this.money.replace(/^\./g,"");//验证第一个字符是数字而不是字符
      this.money = this.money.replace(/\.{2,}/g,".");//只保留第一个.清除多余的
      this.money = this.money.replace(".","$#$").replace(/\./g,"").replace("$#$",".");
      this.money = this.money.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');//只能输入两个小数
    }
  },

  computed: {},
  filters:{
    withdrawalType(val){
      if(val == 0){
        return '审核中'
      }
      if(val == '1'){
        return '提现成功'
      }
      if(val == '2'){
        return  '提现失败'
      }
    },
  },
  created(){
    this.getWithdrawData()
  },

  methods: {
    getWithdrawData(){
      let params = {
        page:this.pageIndex,
        limit:10,
      }
      getWithdraw(params).then(res=>{
        if(res.code ==1){
          let arr = res.data.list
          for(let item in arr){
            this.tableList.push(arr[item])
          }
          this.withdrawInfo = res.data
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },
    //计算实际到账金额
    countRealmoney(){
      this.realMoney = (this.money -  ( (this.money  * this.withdrawInfo.rate) /100)).toFixed(2)

      console.log('提现金额',this.realMoney)
    },
    handleCurrentChange(val){
      this.pageIndex = val
      this.getWithdrawData()
    },
    submit(){
      let reg = /^1[3-9]\d{9}$/
      if(this.money == ''){
        return this.$Message.error('请输入金额')
      }else if(this.aliName == ''){
        return this.$Message.error('请输入姓名')
      }else if(this.aliAccount == ''){
        return this.$Message.error('请输入账号')
      }
      let params = {
        price : this.money,
        name:this.aliName,
        account:this.aliAccount
      }
      sendWithdraw(params).then(res=>{
        if(res.code == 1){
          console.log('res',res)
          this.$Message.success(`${res.msg}`)
          this.money = ''
          this.aliAccount = ''
          this.aliName = ''
          this.getWithdrawData()
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },
    eventScrooll(e){
      if (Math.round(e.srcElement.scrollTop + 1 ) + e.srcElement.clientHeight >= e.srcElement.scrollHeight && e.srcElement.scrollHeight != 0) {
        this.pageIndex++
        // this.getshareRecordData()
        console.log('执行了吗')
        this.getWithdrawData()

      }
    },
  },

}

</script>
<style lang='less' scoped>
@import '@assets/css/profile/account/requestWithdrawal.less';
</style>